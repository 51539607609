<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="165"
       height="28"
       viewBox="0 0 165 28"
       fill="none"
  >
    <path d="M121.09 17.07V27.43H115.75V18.27C115.75 15.14 113.86 13.9 112.1 13.9C109.21 13.9 107.52 16.45 107.52 20.3V27.43H102.18V9.12H107.52V12.25H107.66C108.69 10.32 110.93 9.12 113.72 9.12C118.16 9.12 121.09 12.42 121.09 17.07Z"
          fill="white"
    />
    <path d="M133.6 27.43C128.4 27.43 124.2 23.47 124.2 18.3C124.2 13.13 128.43 9.17 133.8 9.17C138.8 9.17 142.9 13.2 142.9 18.1C142.9 18.64 142.83 19.34 142.73 19.74L129.67 19.77C130.17 21.62 131.65 22.72 133.66 22.72C135.04 22.72 136.38 22.08 137.05 21.14H142.52C141.45 24.8 137.85 27.42 133.59 27.42L133.6 27.43ZM137.76 16.75C137.49 14.94 135.75 13.56 133.73 13.56C131.62 13.56 130.1 14.77 129.63 16.75H137.75H137.76Z"
          fill="white"
    />
    <path d="M145.94 18.32C145.94 13.23 150.18 9.16 155.5 9.16C160.82 9.16 165 13.24 165 18.32C165 23.4 160.76 27.48 155.47 27.48C150.18 27.48 145.94 23.41 145.94 18.32ZM159.85 18.32C159.85 15.9 157.86 13.91 155.47 13.91C153.08 13.91 151.06 15.9 151.06 18.32C151.06 20.74 153.05 22.77 155.47 22.77C157.89 22.77 159.85 20.85 159.85 18.32Z"
          fill="white"
    />
    <path d="M23.14 9.12V19.45C23.14 23 25.69 25.69 29.31 25.69C32.93 25.69 35.44 23.07 35.44 19.52V9.12H37.38V19.66C37.38 24.14 34.08 27.44 29.31 27.44C24.54 27.44 21.17 24.14 21.17 19.66V9.12H23.14Z"
          fill="white"
    />
    <path d="M44.56 10.84V23.86C44.56 25.04 45.31 25.8 46.42 25.8H50.4V27.49H46.1C43.98 27.49 42.62 26.02 42.62 23.8V10.86H39.86V9.14H42.62V4.95H44.56V9.14H50.4V10.86H44.56V10.84Z"
          fill="white"
    />
    <path d="M66.47 27.48H64.53V10.84H57.58V27.48H55.64V10.84H52.88V9.12H55.64V6.14C55.64 2.66 57.72 0.51 60.7 0.51H64.65V2.23H60.88C58.91 2.23 57.58 3.74 57.58 6.35V9.11H66.47V27.47V27.48Z"
          fill="white"
    />
    <path d="M73.67 10.84V23.86C73.67 25.04 74.42 25.8 75.53 25.8H79.51V27.49H75.21C73.09 27.49 71.73 26.02 71.73 23.8V10.86H68.97V9.14H71.73V4.95H73.67V9.14H79.51V10.86H73.67V10.84Z"
          fill="white"
    />
    <path d="M94.89 4.17H81.98V2.31H97.22V3.71L84.5 27.48H82.38L94.89 4.17Z"
          fill="white"
    />
    <path d="M0.660004 18.28C0.660004 13.08 4.69 9.12 9.89 9.12C15.09 9.12 19.09 13.15 19.09 18.28C19.09 23.41 15.09 27.44 9.89 27.44C4.69 27.44 0.660004 23.48 0.660004 18.28ZM17.28 18.28C17.28 14.07 14.17 10.78 9.92 10.78C5.67 10.78 2.49 13.96 2.49 18.28C2.49 22.6 5.67 25.81 9.92 25.81C14.17 25.81 17.28 22.56 17.28 18.28Z"
          fill="white"
    />
  </svg>
</template>
